@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.ChapterOverview {
	@include flex('center', 'center');
	position: relative;
	width: 100%;	
	height: 100%;
	// min-height: 57em;
	background-image: url('../../assets/images/background-autumn.jpg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	flex-direction: column;
	overflow:hidden;

	.ChapterOverview-menu {
		position: absolute;
		top: -0.5em;
		left: -0.5em;
		width: 22em;
		height: 22em;
		padding-top: 1.4em;
		text-align: left;
		background-image: url('../../assets/images/background-menu.png');
		background-size: contain;
		background-position: left top;
		background-repeat: no-repeat;
		ul {
			list-style: none;
			padding-left: 2em;
		}		
		li {
			position: relative;
			font-size: 1.75em;
			line-height: 1.75;
			font-weight: bold;
			color: $green;
			padding-left: 1em;
			cursor: pointer;
			&:hover {@include scale(1.01);}	
			&:before {
				content: '';
				position: absolute;
				background-image: url('../../assets/images/icon-menu-bullet.svg');
				width: 1em;
				height: 1em;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
				left: 0;
				top: 0.25em;
			}
		}
		.ChapterOverview-title {
			width: 100%;
			height: 6.4em;
			margin-bottom: 1em;
			background-image: url('../../assets/images/title-small.svg');
			background-size: 16.9em auto;
			background-repeat: no-repeat;
			background-position: left 1.7em center;
		}
	}

	.ChapterOverview-chapters {
		position: relative;
		width: 62.4em;
		height: 45em;
		// margin-bottom: 7em;
		margin-top: -5em;
		text-align: center;
		.ChapterOverview-chapter-wraper {
			position: relative;
			display:inline-block;
			width: 16.5em;
			height: 20.8em;
			margin-left: 2em;
			.ChapterOverview-chapter {
				width: 14.5em;
				height: 20.8em;
				background-image: url('../../assets/images/chapter-overview-1.svg');
				background-repeat: no-repeat;
				background-size: 100% auto;
				background-position: top center;
				margin-left: -0.5em;
				margin-top: 2em;
				cursor: pointer;
				&--1 {}
				&--2 {background-image: url('../../assets/images/chapter-overview-2.svg');}
				&--3 {background-image: url('../../assets/images/chapter-overview-3.svg');}
				&--4 {background-image: url('../../assets/images/chapter-overview-4.svg');}
				&--5 {background-image: url('../../assets/images/chapter-overview-5.svg');}
			}
			&:hover{@include scale(1.01);}
			
	
			.ChapterOverview-badges {
				position: absolute;
				width: 100%;
				height: 2em;
				bottom: 0;
				left: 0;
			}
		}
	}

	.ChapterOverview-aboutBtn {
		position: absolute;
		bottom: 2.239em;
		left: 95.022em;
		width: 9.17em;
		height: 9.17em;
		background-image: url('../../assets/images/logos/logo-blivnaturligvisround.svg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		&:hover {@include scale(1.01);}
		cursor: pointer;
	}
	.ChapterOverview-nordea {
		position: absolute;
		z-index: 2;
		bottom: 2.2em;
		left: 6.3em;
		width: 12.10em;
		height: 5.5em;
		background-image: url('../../assets/images/logos/logo-nordeafonden.png');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
	}
}