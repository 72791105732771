@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.BackButton {
	position: absolute;
	top: 0em;
	left: 0em;
	height: 6em;
	width: 10em;
	z-index: 4;
	background-image: url('../../../assets/images/icon-arrow-back-white.svg');
	background-size: 5.5em auto;
	background-position: top 2.5em left 2em;
	background-repeat: no-repeat;
	cursor: pointer;
	&:hover {@include scale(1.05);}
	&--red {background-image: url('../../../assets/images/icon-arrow-back-red.svg');}
}