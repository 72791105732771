@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Story {
	height: 100%;
	width: 100%;
	// background-color: $beige;
	overflow: hidden;
	user-select: none;
	.BackButton {
		z-index: 10;
	}
	&--configure {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}
}

.Story-video {
	position: absolute;
	top: 0;
	left: 0;
	background-size: contain;
	background-position: top left;
	// mix-blend-mode: darken;
	@include no-select();		
}

.Story-header {
	position: absolute;
	top: 0;
	left: 0;
	text-align: left;
	width: 45.666em;
	padding-top: 5.711112em;
	background-color: rgb($beige, 1);
	margin-left: 58.94445em;
	border-radius: 0.277778em;
	margin-top: 1.555556em;
	height: 6.56em;
	z-index: 10;
	@include transition(opacity, 0.5, 'ease');
	transition-delay: 0.5s;
	opacity: 0.5;
	&--open{
		opacity: 1;
	}
	&:hover{
		opacity: 1;
		transition-delay: 0s;
	}
}

.Story-iconReading {
	position: absolute;
	background-image: url('../../assets/images/icon-read-new.svg');
	width: 9.7222em;
	height: 4.5em;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% auto;
	top: 0.8em;
	right: 2em;
	overflow: hidden;
	cursor: pointer;
	
	&--open{
		background-image: url('../../assets/images/icon-read-two.svg');
	}
	.Story-iconReading-arrow {
		position: absolute;
		top: 1.3em;
		right: 1em;

		width: 1.95em;
		height: 1.975em;
		background-image: url('../../assets/images/icon-arrow-up-new.svg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% auto;
		transform: rotate(180deg);
		@include transition(all, 0.5, ease-in);
	}
	.Story-iconReading-arrow--open {
		top: 1.2em;
		transform: rotate(0deg);
	}

}
.Story-bodyWrapper {
	@include transition(height, 0.5, ease);
	height: 0em;
	display: block;
	position: absolute;
	top: 8.25em;
	left: 0;
	margin-left: 58.95445em;
	margin-top: -0.3em;
	width: 45.666em;
	background-color: $beige;
	border-radius: 0.2227em;
	z-index: 10;
	&--open {
		visibility: visible;
		height: 40.0872223em;
		padding-bottom: 1em;
		.Story-text {
			padding: 1em 1em 0 2em;
		}
		.Story-info,
		.Story-title {
			display: block;
		}
	}
	.Story-body {
		width: 100%;
		padding-bottom: 1em;
		height: 100%;
		border-radius: 0.277778em;
		.Story-info {
			opacity: 0;
			@include transition(opacity, 0.5, ease-in);
			text-align: left;
			padding-left: 2.572222em;
			span {
				font-size: 1.4444444445em;
				font-weight: bold;
				line-height: 2em;
			}
			&--open {
				opacity: 1;
			}
		}
		.Story-title {
			opacity: 0;
			@include transition(opacity, 0.5, ease-in);
			text-align: left;
			padding-left: 2.572222em;
			span {
				color: $green;
				font-family: Antropos;
				font-size: 2.7777778em;
			}
			&::after {
				content: ""; 
				display: none;
			}
			&--open {
				opacity: 1;
				&::after {
					content: ""; 
					display: block;
					margin-left: -0.5em;
					width: 41.1805556em;
					border-color: $dark-green;
					border-width: 0 0 0.16667em 0;
					border-style: solid;
					@include transition(opacity, 0.5, 'ease');
					}
			}
		}

		.Story-text {
			text-align: left;
			padding: 0em 1em 0 2em;
			height: 87%;
			margin-right: 1em;
			p {
				font-size: 1.44445em;
				line-height: 1.67;
				margin-top: 0;
			}
			overflow-y: auto;
		
			&::-webkit-scrollbar {
				width: 0em;
			}
			
			
			&--open{
				&::-webkit-scrollbar {
					width: 0.77em;
				}
			}
			/* Track */
			&::-webkit-scrollbar-track {
				border-radius: 0.38em;
				background-color: $track;
				margin-top: 0.55em;
				margin-bottom: 0.55em;
			}
				
			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: $dark-green; 
				border-radius: 0.38em;
			}
			
			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: $thumbHover; 
			}
		
		}
	}
}


.Story-picture {
	position: relative;
	width: 100%;
	height: 100%;
	top: 0;
	left:0;
}




.Story-audio {
	position: absolute;
	border-radius: 50%;
	@include opacity(1);
	@include transition(opacity, 0.5, 'ease');
	top: -1.8em;
	right: 0em;
	.Story-audio-Title {
		position: absolute;
		top: 3.25em;
		left: 9em;
		span {
			color: rgb(255, 199, 68);
		}
	}
}

.Story-animation {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}