@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import 'animations';

.chapterOne {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

}	

.chapterOne-background {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-1/background.png');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	z-index: 1;
}
.chapterOne-deer {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-1/deer.png');
	background-position: 16em 16em;
	background-repeat: no-repeat;
	background-size: 50% auto;
	z-index: 2;
	.chapterOne-deer-ear {
		z-index: 2;
		position: absolute;
		width: 50em;
		height: 50em;
		background-image: url('../../../assets/images/backgrounds-story/animation/chapter-1/deer-ear.png');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 100% auto;
		top: 10em;
		left: 17.8em;
		@include origin(center, center);
		-webkit-animation: deerEar 8s linear 1s infinite;
		animation: deerEar 8s linear 1s infinite;
	}

}

.chapterOne-fog {
	position: absolute;
	width: 200%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-1/fog.png');
	background-repeat: repeat-x;
	background-position: center top;
	background-size: 50% auto;
	z-index: 2;
	
	@include animation(panRightScale, 100, 0, infinite, normal, linear, none);
}


.chapterOne-foreground {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-1/foreground.png');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	z-index: 3;
}



@-webkit-keyframes deerEar {
	0% {@include rotate(0.2deg);}
	3% {@include rotate(0deg);}
	3.5% {@include rotate(-0.6deg);}
	4% {@include rotate(0deg);}
	55% {@include rotate(0.1deg);}
	55.5% {@include rotate(-0.5deg);}
	56% {@include rotate(0deg);}
	70% {@include rotate(0deg);}
	70.5% {@include rotate(-0.8deg);}
	71% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}
@keyframes deerEar {
	0% {@include rotate(0.2deg);}
	3% {@include rotate(0deg);}
	3.5% {@include rotate(-0.6deg);}
	4% {@include rotate(0deg);}
	55% {@include rotate(0.1deg);}
	55.5% {@include rotate(-0.5deg);}
	56% {@include rotate(0deg);}
	70% {@include rotate(0deg);}
	70.5% {@include rotate(-0.8deg);}
	71% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}