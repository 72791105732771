@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
// @import 'animations';


.chapterFour {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}	

.chapterFour-background {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-4/background.png');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	z-index: 1;
}
.chapterFour-waves {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-4/water-01.png');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	z-index: 1;
	-webkit-animation: chapterFourWater 7.5s ease-in-out infinite;
	animation: chapterFourWater 7.5s ease-in-out infinite;
}

.chapterFour-sunbeam {
	z-index: 2;
	position: absolute;
	left: 0;
	top: -2em;
	width: 100%;
	height: 100%;
	background-position: top right;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url('../../../assets/images/backgrounds-story/animation/shared/sunbeams1-blur5.png');

	@include origin(top, center);
	@include translateAndRotate(-10em, 0, 0, 335deg);
	&--1 {background-image: url('../../../assets/images/backgrounds-story/animation/shared/sunbeams1-blur5.png');}
	&--2 {background-image: url('../../../assets/images/backgrounds-story/animation/shared/sunbeams1-blur3.png');}
	&--3 {background-image: url('../../../assets/images/backgrounds-story/animation/shared/sunbeams1-blur1.png');}
	&--4 {background-image: url('../../../assets/images/backgrounds-story/animation/shared/sunbeams1-blur2.png');}
	&--5 {background-image: url('../../../assets/images/backgrounds-story/animation/shared/sunbeams1-blur1.png');}
	&--6 {background-image: url('../../../assets/images/backgrounds-story/animation/shared/sunbeams1-blur4.png');}

	&--1 {
		@include opacity(1);
		-webkit-animation: sunbeam-4 11s ease-in-out 0s infinite, sunbeamTranslate 17s ease-in-out 3s infinite;
		animation: sunbeam-4 11s ease-in-out 0s infinite, sunbeamTranslate 17s ease-in-out 3s infinite;
		left: 1em;
	}
	&--2 {
		@include opacity(0);
		-webkit-animation: sunbeam-5 11s ease-in-out 0s infinite, sunbeamTranslate 15s ease-in-out 2s infinite;
		animation: sunbeam-5 11s ease-in-out 0s infinite, sunbeamTranslate 15s ease-in-out 5s infinite;
		left: -3em;
	}
	&--3 {
		@include opacity(0);
		-webkit-animation: sunbeam-6 11s ease-in-out 0s infinite, sunbeamTranslate 13s ease-in-out 0s infinite;
		animation: sunbeam-6 11s ease-in-out 0s infinite, sunbeamTranslate 13s ease-in-out 0s infinite;
		left: -5em;
	}
	&--4 {
		@include opacity(1);
		left: 3em;
		-webkit-animation: sunbeam-4 11s ease-in-out 0s infinite, sunbeamTranslate2 11s ease-in-out 3s infinite;
		animation: sunbeam-4 11s ease-in-out 0s infinite, sunbeamTranslate2 11s ease-in-out 3s infinite;
	}
	&--5 {
		@include opacity(0);
		left: -2em;
		-webkit-animation: sunbeam-5 11s ease-in-out 0s infinite, sunbeamTranslate2 7s ease-in-out 2s infinite;
		animation: sunbeam-5 11s ease-in-out 0s infinite, sunbeamTranslate2 7s ease-in-out 5s infinite;

	}
	&--6 {
		@include opacity(0);
		left: 0em;
		-webkit-animation: sunbeam-6 11s ease-in-out 0s infinite, sunbeamTranslate2 13s ease-in-out 0s infinite;
		animation: sunbeam-6 11s ease-in-out 0s infinite, sunbeamTranslate2 13s ease-in-out 0s infinite;
	}
}


.chapterFour-dust {
	position: absolute;
	width: 100%;
	height: 100%;
	.chapterFour-dustParticle {
		z-index: 2;
		position: absolute;
		width: 100%;
		height: 100%;
		background-position: top 0 left 0;
		background-size: 100% 100%;
		background-repeat: repeat;
		@include opacity(0.25);
		@include translate3d(0, 0, 0,);
		&--1 {
			background-image: url('../../../assets/images/backgrounds-story/animation/shared/dust1.png');
			-webkit-animation: dustParticles1 40s linear 0s infinite;
			animation: dustParticles1 40s linear 0s infinite;
		}
		&--2 {
			background-image: url('../../../assets/images/backgrounds-story/animation/shared/dust2.png');
			-webkit-animation: dustParticles2 40s linear 0s infinite;
			animation: dustParticles2 40s linear 0s infinite;
		}
	}
}



// .chapterFour-foreground {
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-4/foreground.png');
// 	background-repeat: no-repeat;
// 	background-position: center top;
// 	background-size: cover;
// 	z-index: 3;
// }