
/* chapter 1 */
@keyframes panRightScale {
	0% {
		@include translateAndscale(-100vw, 1);
		@include opacity(0.6);
	}
	33% {
		@include translateAndscale(-66vw, 1.2);
		@include opacity(0.8);
		}
	66% {
		@include translateAndscale(-33vw, 0.8);
		@include opacity(0.5);
		}
	100% {
		@include translateAndscale(0vw, 1);
		@include opacity(0.6);
		}
}
@-webkit-keyframes panRightScale {
	0% {
		@include translateAndscale(-100vw, 1);
		@include opacity(0.6);
	}
	33% {
		@include translateAndscale(-66vw, 1.2);
		@include opacity(0.8);
		}
	66% {
		@include translateAndscale(-33vw, 0.8);
		@include opacity(0.5);
		}
	100% {
		@include translateAndscale(0vw, 1);
		@include opacity(0.6);
		}
}

/* chapter 1 */
@keyframes panRightOpacity {
	0% {
		@include translateAndscale(-100vw, 1);
		@include opacity(0);
	}
	10% { @include opacity(1);}
	33% {
		@include translateAndscale(-66vw, 1);
		@include opacity(1.2);
		}
	66% {
		@include translateAndscale(-33vw, 1);
		@include opacity(1);
		}
	90% { @include opacity(1);}
	100% {
		@include translateAndscale(0vw, 1);
		@include opacity(0);
		}
}
@-webkit-keyframes panRightOpacity {
	0% {
		@include translateAndscale(-100vw, 1);
		@include opacity(0);
	}
	10% { @include opacity(1);}
	33% {
		@include translateAndscale(-66vw, 1);
		@include opacity(1.2);
		}
	66% {
		@include translateAndscale(-33vw, 1);
		@include opacity(1);
		}
	90% { @include opacity(1);}
	100% {
		@include translateAndscale(0vw, 1);
		@include opacity(0);
		}
}


/* chapter 1 */
@keyframes panLeftScale {
	0% {
		@include translateAndscale(0vw, 1);
		@include opacity(1);
	}
	33% {
		@include translateAndscale(-33vw, 1.2);
		@include opacity(0.8);
		}
	66% {
		@include translateAndscale(-66vw, 0.8);
		@include opacity(0.9);
		}
	100% {
		@include translateAndscale(-100vw, 1);
		@include opacity(1);
		}
}
@-webkit-keyframes panLeftScale {
	0% {
		@include translateAndscale(0vw, 1);
		@include opacity(1);
	}
	33% {
		@include translateAndscale(-33vw, 1.2);
		@include opacity(0.8);
		}
	66% {
		@include translateAndscale(-66vw, 0.8);
		@include opacity(0.9);
		}
	100% {
		@include translateAndscale(-100vw, 1);
		@include opacity(1);
		}
}


/* Sun beams */
@keyframes sunbeam-1 {
	0% {@include opacity(1);}
	33% {@include opacity(0);}
	60% {@include opacity(1);}
}
@keyframes sunbeam-2 {
	0% {@include opacity(0);}
	20% {@include opacity(1);}
	45% {@include opacity(0);}
	70% {@include opacity(1);}
	100% {@include opacity(0);}
}
@keyframes sunbeam-3 {
	0% {@include opacity(0);}
	40% {@include opacity(1);}
	60% {@include opacity(0);}
	80% {@include opacity(1);}	
	100% {@include opacity(0);}
}

@keyframes sunbeam-4 {
	0% {@include opacity(0.75);}
	33% {@include opacity(0);}
	60% {@include opacity(0.75);}
}
@keyframes sunbeam-5 {
	0% {@include opacity(0);}
	20% {@include opacity(0.75);}
	45% {@include opacity(0);}
	70% {@include opacity(0.75);}
	100% {@include opacity(0);}
}
@keyframes sunbeam-6 {
	0% {@include opacity(0);}
	40% {@include opacity(0.75);}
	60% {@include opacity(0);}
	80% {@include opacity(0.75);}	
	100% {@include opacity(0);}
}


@-webkit-keyframes sunbeamTranslate {
	0% {@include translateAndRotate(3em, -2em, 0, 335deg);}
	50% {@include translateAndRotate(-2em, -2em, 0, 335deg);}
	100% {@include translateAndRotate(3em, -2em, 0, 335deg);}
}
@keyframes sunbeamTranslate {
	0% {@include translateAndRotate(3em, -2em, 0, 335deg);}
	50% {@include translateAndRotate(-2em, -2em, 0, 335deg);}
	100% {@include translateAndRotate(3em, -2em, 0, 335deg);}
}
@-webkit-keyframes sunbeamTranslate2 {
	0% {@include translateAndRotate(-3em, -2em, 0, 335deg);}
	50% {@include translateAndRotate(3em, -2em, 0, 335deg);}
	100% {@include translateAndRotate(-3em, -2em, 0, 335deg);}
}
@keyframes sunbeamTranslate2 {
	0% {@include translateAndRotate(-3em, -2em, 0, 335deg);}
	50% {@include translateAndRotate(3em, -2em, 0, 335deg);}
	100% {@include translateAndRotate(-3em, -2em, 0, 335deg);}
}



// @include translateAndRotate(-46em, 0, 0, 278deg);
@-webkit-keyframes sunbeamTranslate3 {
	0% {@include translateAndRotate(-43em, -2em, 0, 278deg);}
	50% {@include translateAndRotate(-48em, -2em, 0, 278deg);}
	100% {@include translateAndRotate(-43em, -2em, 0, 278deg);}
}
@keyframes sunbeamTranslate3 {
	0% {@include translateAndRotate(-43em, -2em, 0, 278deg);}
	50% {@include translateAndRotate(-48em, -2em, 0, 278deg);}
	100% {@include translateAndRotate(-43em, -2em, 0, 278deg);}
}
@-webkit-keyframes sunbeamTranslate4 {
	0% {@include translateAndRotate(-49em, -2em, 0, 278deg);}
	50% {@include translateAndRotate(-43em, -2em, 0, 278deg);}
	100% {@include translateAndRotate(-49em, -2em, 0, 278deg);}
}
@keyframes sunbeamTranslate4 {
	0% {@include translateAndRotate(-49em, -2em, 0, 278deg);}
	50% {@include translateAndRotate(-43em, -2em, 0, 278deg);}
	100% {@include translateAndRotate(-49em, -2em, 0, 278deg);}
}


/* dust particles*/
@-webkit-keyframes dustParticles1 {
	0% {background-position: top 0 left 0;}
	25% {background-position: top 25vh left 25vw;}
	50% {background-position: top 40vh left 50vw;}
	75% {background-position: top 75vh left 65vw;}
	100% {background-position: top 100vh left 100vw;}
}
@keyframes dustParticles1 {
	0% {background-position: top 0 left 0;}
	25% {background-position: top 25vh left 25vw;}
	50% {background-position: top 40vh left 50vw;}
	75% {background-position: top 75vh left 65vw;}
	100% {background-position: top 100vh left 100vw;}
}
@-webkit-keyframes dustParticles2 {
	0% {background-position: top 0 left 0;}
	25% {background-position: top 50vh left 20vw;}
	50% {background-position: top 100vh left 40vw;}
	75% {background-position: top 150vh left 75vw;}
	100% {background-position: top 200vh left 100vw;}
}
@keyframes dustParticles2 {
	0% {background-position: top 0 left 0;}
	25% {background-position: top 50vh left 20vw;}
	50% {background-position: top 100vh left 40vw;}
	75% {background-position: top 150vh left 75vw;}
	100% {background-position: top 200vh left 100vw;}
}

/* rain particles*/
@-webkit-keyframes rainparticles1 {
	0% {background-position: top 0 left 0;}
	25% {background-position: top 100vh left 25vw;}
	50% {background-position: top 200vh left 50vw;}
	75% {background-position: top 300vh left 75vw;}
	100% {background-position: top 400vh left 100vw;}
}
@keyframes rainparticles1 {
	0% {background-position: top 0 left 0;}
	25% {background-position: top 100vh left 25vw;}
	50% {background-position: top 200vh left 50vw;}
	75% {background-position: top 300vh left 75vw;}
	100% {background-position: top 400vh left 100vw;}
}

/* chapter seven */
@-webkit-keyframes chapterSevenWater {
	from {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-7/water-01.png');}
	to {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-7/water-02.png');}
}
@keyframes chapterSevenWater {
	from {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-7/water-01.png');}
	to {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-7/water-02.png');}
}

/* chapter six*/
@-webkit-keyframes chapterSixWater {
	0% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-01.png');}
	15% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-02.png');}
	30% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-03.png');}
	45% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-04.png');}
	60% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-05.png');}
	75% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-06.png');}
	90% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-01.png');}
	100% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-01.png');}
	// 60% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-05.png');}
	// 70% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-04.png');}
	// 80% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-03.png');}
	// 90% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-02.png');}
	// 100% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-01.png');}
}
@keyframes chapterSixWater {
	0% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-01.png');}
	15% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-02.png');}
	30% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-03.png');}
	45% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-04.png');}
	60% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-05.png');}
	75% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-06.png');}
	90% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-01.png');}
	100% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-01.png');}
}

/* chapter Nine*/
@-webkit-keyframes chapterNineWater {
	0% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-9/water-01.png');}
	25% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-9/water-02.png');}
	50% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-9/water-03.png');}
	75% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-9/water-04.png');}
	85% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-9/water-01.png');}
}
@keyframes chapterNineWater {
	0% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-9/water-01.png');}
	25% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-9/water-02.png');}
	50% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-9/water-03.png');}
	75% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-9/water-04.png');}
	85% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-9/water-01.png');}
}
/* chapter Five*/
@-webkit-keyframes chapterFive {
	0% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-01.png');}
	39% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-01.png');}

	40% {background-image: none;}
	79% {background-image: none;}

	80% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-01.png');}
	86% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-01.png');}

	87% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-02.png');}
	93% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-02.png');}

	94% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-03.png');}
	97% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-03.png');}

	98% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-04.png');}
	100% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-04.png');}
}
@keyframes chapterFive {
	0% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-01.png');}
	39% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-01.png');}

	40% {background-image: none;}
	64% {background-image: none;}

	65% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-01.png');}
	86% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-01.png');}

	87% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-02.png');}
	93% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-02.png');}

	94% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-03.png');}
	97% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-03.png');}

	98% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-04.png');}
	100% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-5/squirrel-04.png');}
}

/* chapter two*/
@-webkit-keyframes chapterFourWater {
	0% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-4/water-01.png');}

	20% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-4/water-02.png');}

	40% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-4/water-03.png');}

	60% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-4/water-04.png');}

	80% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-4/water-05.png');}

	100% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-4/water-01.png');}
}
@keyframes chapterFourWater {
	0% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-4/water-01.png');}

	20% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-4/water-02.png');}

	40% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-4/water-03.png');}

	60% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-4/water-04.png');}

	80% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-4/water-05.png');}
	
	100% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-4/water-01.png');}
}
/* chapter three*/
@-webkit-keyframes chapterThreetjur {
	0% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-01.png');}
	2% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}
	3% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}
	4% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}



	65% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}
	66% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}

	68% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}
	69% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}

	71% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}
	72% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}

	74% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}
	75% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}

	76% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}
	77% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}

	78% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}
	79% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}

	80% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}
	81% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}

	82% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}
	83% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}

	84% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}
	85% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}


	90% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}
	91% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-01.png');}
	
	100% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}
}
@keyframes chapterThreetjur {
	0% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-01.png');}
	2% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}
	3% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}
	4% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}



	65% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}
	66% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}

	68% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}
	69% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}

	71% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}
	72% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}

	74% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}
	75% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}

	76% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}
	77% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}

	78% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}
	79% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}

	80% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}
	81% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}

	82% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}
	83% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}

	84% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-03.png');}
	85% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}


	90% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}
	91% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-01.png');}
	
	100% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-3/tjur-02.png');}
}

/* chapter 2*/
@-webkit-keyframes chapterTwoHare {
	0% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-01.png');}
	2% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-02.png');}

	20% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-02.png');}
	21% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-03.png');}

	40% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-03.png');}
	41% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-02.png');}

	60% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-02.png');}
	61% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-03.png');}

	75% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-03.png');}
	76% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-02.png');}

	80% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-02.png');}
	81% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-01.png');}

	100% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-01.png');}
}
@keyframes chapterTwoHare {
	0% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-01.png');}
	2% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-02.png');}

	20% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-02.png');}
	21% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-03.png');}

	40% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-03.png');}
	41% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-02.png');}

	60% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-02.png');}
	61% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-03.png');}

	75% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-03.png');}
	76% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-02.png');}

	80% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-02.png');}
	81% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-01.png');}

	100% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-2/hare-01.png');}
}


/* chapter 10 */ 
@-webkit-keyframes chapterTen {
	0% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-10/water-01.png');}
	25% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-10/water-02.png');}
	50% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-10/water-03.png');}
	75% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-10/water-02.png');}
	100% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-10/water-01.png');}
}
@keyframes chapterTen {
	0% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-10/water-01.png');}
	25% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-10/water-02.png');}
	50% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-10/water-03.png');}
	75% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-10/water-02.png');}
	100% {background-image: url('../../../assets/images/backgrounds-story/animation/chapter-10/water-01.png');}
}


/* skewing for chapter 7, 9 */ 
@mixin skewingAnimation($name, $to, $from, $duration, $delay:0) {
	@include origin(bottom, right);
	@include animation($name, $duration, $delay, infinite, normal, ease-in-out, none);

	@keyframes #{$name} {
		0% {@include skew($from);}
		50% {@include skew($to);}
		100% {@include skew($from);}
	}
	@-webkit-keyframes #{$name} {
		0% {@include skew($from);}
		50% {@include skew($to);}
		100% {@include skew($from);}
	}
}

/* paning for chapter 9 */ 
@mixin panningAnimation($name, $to:-100vw, $from: 0vw, $duration: 100, $delay: 0) {
	// -webkit-animation: panRight 100s linear 0s infinite;
	// animation: panRight 100s linear 0s infinite;
	@include animation($name, $duration, $delay, infinite, normal, linear, none);

	/* chapter Nine */ 
	@keyframes #{$name} {
		from {@include translate($to);}
		to {@include translate($from);}
	}
	@-webkit-keyframes #{$name} {
		from {@include translate($to);}
		to {@include translate($from);}
	}
}

/* paning for chapter 9 */ 
@mixin glowAnimation($name, $duration: 100, $delay: 0, $randomNumberOne , $randomNumberTwo, $randomNumberThree) {

	@include animation($name, $duration, $delay, infinite, normal, linear, none);
	/* chapter Nine */ 
	@keyframes #{$name} {
		0% {@include opacity(0.5);}
		25% {@include opacity($randomNumberOne);}
		50% {@include opacity($randomNumberTwo);}
		75% {@include opacity($randomNumberThree);}
		100% {@include opacity(0.5);}
	}
	@-webkit-keyframes #{$name} {
		0% {@include opacity(0.5);}
		25% {@include opacity($randomNumberOne);}
		50% {@include opacity($randomNumberTwo);}
		75% {@include opacity($randomNumberThree);}
		100% {@include opacity(0.5);}
	}
}


/* chapter Nine*/
@-webkit-keyframes bird {
	100% {background-position: -108.67em 0;}
}
@keyframes bird {
	100% {background-position: -108.67em 0;}
}

/* chapter Nine*/
@-webkit-keyframes birdflight {
	0% {
		@include scale(0.2);
		top: 12em;
		left: 32em;
	}
	50% {
		@include opacity(1);
	}
	100% {
		top: -10em;
		left: -18em;
		@include scale(1);
		@include opacity(1);
	}
}
@keyframes birdflight {
	0% {
		@include scale(0.2);
		top: 12em;
		left: 32em;
	}
	50% {
		@include opacity(1);
	}
	100% {
		top: -10em;
		left: -18em;
		@include scale(1);
		@include opacity(1);
	}
}

/* chapter Six*/
@-webkit-keyframes birdflight2 {
	0% {
		transform: scaleX(-0.2) scaleY(0.2);
		top: 19em;
		left: -2em;
	}
	50% {
		@include opacity(1);
	}
	100% {
		top: 2em;
		left: 40em;
		transform: scaleX(-1) scaleY(1);
		@include opacity(1);
	}
}
@keyframes birdflight2 {
	0% {
		transform: scaleX(-0.2) scaleY(0.2);
		top: 19em;
		left: -2em;
	}
	50% {
		@include opacity(1);
	}
	100% {
		top: 2em;
		left: 40em;
		transform: scaleX(-1) scaleY(1);
		@include opacity(1);
	}
}
