@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import 'animations';

.chapterSeven {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}	

.chapterSeven-background {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-7/background.png');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	z-index: 1;
}
.chapterSeven-water {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-7/water-01.png');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	z-index: 4;
	-webkit-animation: chapterSevenWater 2s linear infinite alternate;
	animation: chapterSevenWater 2s linear infinite alternate;
	
}


.chapterSeven-clouds {
	position: absolute;
	width: 200%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-7/clouds.png');
	background-repeat: repeat-x;
	background-position: center top;
	background-size: 50% auto;
	z-index: 2;
	
	@include panningAnimation(panRightTwo, -100vw, 0vw, 100);
}


.chapterSeven-foreground {
	position: absolute;
	width: 100%;
	height: 100%;
	// background-image: url('../../../assets/images/backgrounds-story/animation/chapter-7/foreground-01.png');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;

	&--1 {
		background-image: url('../../../assets/images/backgrounds-story/animation/chapter-7/foreground-01.png');
		z-index: 3;
	}
	&--2 {
		background-image: url('../../../assets/images/backgrounds-story/animation/chapter-7/foreground-02.png');
		z-index: 4;
	}
}

.chapterSeven-grass {
	position: absolute;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	&--1 {
		z-index: 5;
		background-image: url('../../../assets/images/backgrounds-story/animation/chapter-7/grass-1.png');
		@include skewingAnimation(grassSkewOne, -5deg, 0deg, 6, 1.25);
	}
	&--2 {
		z-index: 5;
		background-image: url('../../../assets/images/backgrounds-story/animation/chapter-7/grass-2.png');

		@include skewingAnimation(grassSkewTwo, -10deg, 0deg, 6, 0.5);
	}
	&--3 {
		z-index: 5;
		background-image: url('../../../assets/images/backgrounds-story/animation/chapter-7/grass-3.png');
		@include skewingAnimation(grassSkewthree, -15deg, 0deg, 6, 0);
	}
}
