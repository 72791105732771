@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Stories {
	position: relative;
	height: 100%;
	width: 100%;
	background-image: url('../../assets/images/background-forest.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
	user-select: none;
	padding: 8.625em 12.8125em 0 12.8125em;
	.Stories-downloadPopup {
		position: absolute;
		width: 100%;
		height: 100%;
		top:0;
		left:0;
		@include flex ('center', 'center');
		background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
		z-index: 2;
		.Stories-downloadPopupContentWrapper {
			position: absolute;
			width: 80em;
			height: 40em;
			background-color: #FFF5E2;
			padding: 10em 2em 10em 2em;
			
			overflow: hidden;
			@include flex('center', 'center', column);
			flex-wrap: wrap;
			.Stories-downloadChapter {
				width: 25em;
				height: 3em;
				background-color: #6D9E36;
				border-radius: 0.22em;
				
				&.isDownloading {
					@include opacity(0.75);
					pointer-events: none;
				}

				&:not(:last-child) {
					margin-bottom: 1em;
				}
				@include flex('center', 'center');
				span {
					text-transform: capitalize;
					font-size: 2.5em;
					line-height: 1.85;
					font-weight: bold;
					color: white;
					font-family: Antropos;
					
				}
				cursor: pointer;
				&:hover {
					@include scale(1.05);
					background-color: darken(#6D9E36, 10%);
				}
				&-loading {
					background-image: url('../../assets/images/icon-loading.svg');
					background-size: 1.75em auto;
					background-position: right 0.2em center;
					background-repeat: no-repeat;
					&:hover,
					&:focus,
					&:active {
						background-image: url('../../assets/images/icon-loading.svg');
						background-size: 1.75em auto;
						background-position: right 0.2em center;
						background-repeat: no-repeat;
					}	
				}
			}
			.Stories-downloadWholeStory {
				position: absolute;
				top: 33em;
			}	
		}
		.Stories-closeBtn {
			position: absolute;
			width: 2.03em;
			height: 2.03em;
			top: 1.7em;
			right: 1.93em;
			background-image: url('../../assets/images/icon-close-green.svg');
			background-size: 100% auto;
			cursor: pointer;
			&:hover {
				@include scale(1.05);
			}
		}
	}
}

.Stories-downloadBtn {
	position: absolute;
	top: 0.5em;
	right: 22em;
	width: 7em;
	height: 7em;
	background-image: url('../../assets/images/icon-download.svg');
	background-size: 5em auto;
	background-repeat: no-repeat;
	background-position: center center;
	z-index: 2;
	cursor: pointer;
	&:hover {
		@include scale(1.05);
	}
}

.Stories-infoIcon {
	position: absolute;
	top: 3.35em;
	right: 3.38em;
	width: 2.5em;
	height: 2.7em;
	background-image: url('../../assets/images/icon-info.svg');
	background-repeat: no-repeat;
	background-size: 3.09em auto;
	background-position: center center;
	&:hover {
		@include scale(1.05);
		cursor: pointer;
	}
	&::-webkit-scrollbar{display:none;}
	
}
.Stories-scrollNextbutton,
.Stories-scrollBackbutton {
	position: absolute;
	top: 32.22em;
	z-index: 2;
	right: 0;	
	width: 4.5em;
	height: 4.5em;
	
	&:hover {
		@include scale(1.05);
		cursor: pointer;
	}
}
.Stories-scrollNextbutton {
	background-image: url('../../assets/images/icon-next.svg');
	background-repeat: no-repeat;
	background-size: 100% auto;
	top: 32.22em;
	right: 3.1em;
	background-position: center center;
}
.Stories-scrollBackbutton {
	background-image: url('../../assets/images/icon-prev.svg');
	background-repeat: no-repeat;
	background-size: 100% auto;
	top: 32.22em;
	left: 3.1em;
	background-position: center center;
}
.Stories-wholeStory {
	font-size: 1em;
	position: absolute;
	top: 1.5em;
	right: 7.33em;
}
.Stories-wholeStoryIcon {
	width: 12.67em;
	height: 6.38em;
	background-image: url('../../assets/images/icon-read-story.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: left;
	cursor: pointer;
	&:hover {
		@include scale(1.05);
	}
}

.Stories-header {
	margin-top: -7em;
	span {
		font-size: 1em;
		text-align: left;
		color: white;
		h2 {
			font-size: 2.08em;
			font-family: Antropos;
			margin-top: 0;
			
		}
		p {
			font-size: 1.24em;
			line-height: 2em;
		}
	}
}

.Stories-text {
	margin-top: 1.25em;
	font-size: 2em;
	text-align: left;
	color: white;
	
}

.Stories-body {
	display: flex;
	position: absolute;
	left: 0;
	top: 12.72em;
	width: 100%;
	overflow-x: scroll;
	cursor: grab;
	height: 36em;
	text-align: center;
	&::-webkit-scrollbar{display:none;}
}

.Stories-part {
	position: relative;
	vertical-align: top;
	height: 100%;
	display: inline-block;
	min-width: 26.11em;
	margin-right: 2.11em;
	overflow: hidden;
	&::-webkit-scrollbar{display: none;}
	&:first-child {
		margin-left: 12.11em;
	}
	&:last-child {
		margin-right: 22.11em;
	}
}

.Stories-partHeader {
	width: 100%;
	background-color: $green-light;

	margin: 2px;
	font-size: 1em;
	span {
		text-transform: capitalize;
		font-size: 2.5em;
		line-height: 1.85;
		font-weight: bold;
		color: white;
		font-family: Antropos;
	}
	border-radius: 0.53em 0.58em 0 0;
	@include box-shadow(0, 0.075em, 0.150em, 0, rgba(black, .5)); 
}

.Stories-partBody {
	height: 31em;
	width: 99.5%;
	overflow: auto;
	padding: 0em 2em 1em 2em;
	margin: 2px;
	margin-top: -0.1em;
	background-color: $beige2;
	&::-webkit-scrollbar{display:none;}
	border-radius: 0 0 0.58em 0.58em;
}

.Stories-activity {
	cursor: pointer;
	position: relative;
	width: 100%;
	height: 12.5em;
	margin-top: 1.74em;
	&:first-child {
		margin-top: 1.63em;
	}
	@include box-shadow(0, 0.19em, 0.19em, 0, rgba(black, .3)); 
	overflow: hidden;
	img {
		position: absolute;
		max-width: 21.91em;
		width: auto;
		height: auto;
		max-height: 12.66em;
		left: 0;
		pointer-events: none;
		margin-top: -1.5em;
		margin-left: 0.06em;
	}
	&:hover {
		@include scale(1.01);
	}
}

.Stories-activityText {
	position: absolute;
	width: 100%;
	margin-top: 9.67em;
	height: 2.83em;
	@include no-select();
	text-align: left;
	background-color: $beige;
	background-image: url('../../assets/images/icon-book2.svg');
	background-repeat: no-repeat;
	background-position: 18.5em 0.4em;
	background-size: 2.78em 1.92em;
	@include flex('flex-start','center');
	span {
		font-size: 1.56em;
		font-weight: bold;
		padding-left: 0.96em;
	}
}