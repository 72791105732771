@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.WholeStory {
	position: relative;
	height: 100%;
	width: 100%;
	background-image: url('../../assets/images/background-forest.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
	user-select: none;

	
}

.WholeStory-downloadBtn {
	position: absolute;
	top: 3em;
	right: 14em;
	width: 7em;
	height: 7em;
	background-image: url('../../assets/images/icon-download.svg');
	background-size: 5em auto;
	background-repeat: no-repeat;
	background-position: center center;
	z-index: 2;
	cursor: pointer;
	&:hover {
		@include scale(1.05);
	}
	&-loading {
		&::after {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			z-index: 3;
			width: 7em;
			height: 7em;
			background-image: url('../../assets/images/icon-loading2.svg');
			background-size: 7em auto;
			background-position: center center;
			background-repeat: no-repeat;
			&:hover,
			&:focus,
			&:active {
				background-image: url('../../assets/images/icon-loading2.svg');
				background-size: 7em auto;
				background-position: center center;
				background-repeat: no-repeat;
			}
		}
	}
}

.WholeStory-body {
	position: absolute;
	top: 2.944em;
	left: 12.055556em;
	height: 19.8333333333em;
	width: 82.555em;
	overflow-y: auto;
	&::-webkit-scrollbar {display:none;}
	background-color: $beige;
	margin-top: -0.3em;
	border-radius: 0.277778em;
	padding-top: 2.82777em;
	padding-left: 9.833333em;
	text-align: left;
	span {
	
		font-size: 2.777778em;
		font-family: Antropos;
		color: $dark-green;

	}
	.WholeStory-subtitle {		
		margin-top: 7.6777777777em;
		span {
			color: black;
			font-size: 1.444444444em;
			font-family: "Open Sans", Arial, sans-serif;
			font-weight: bold;
		}
	}
}


.WholeStory-audio {
	position: absolute;
	border-radius: 50%;
	@include opacity(1);
	@include transition(opacity, 0.5, 'ease');
	top: 8.3em;
	height: 4em;
	left: 21.888em;
	z-index: 2;
}