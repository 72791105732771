@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';

@import 'animations';

.chapterTen {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-10/background.png');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
}

.chapterTen-animation {
	width: 100%;
	height: 100%;
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-animation: chapterTen 3s linear 0s infinite;
	animation: chapterTen 3s linear 0s infinite;
}