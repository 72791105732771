@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.About {
	@include no-select();
	height: 100%;
	background-image: url('../../assets/images/background-forest.jpg');
	background-size: cover;
	background-position: bottom center;
	background-repeat: no-repeat;
	overflow: hidden;

	padding: 3em 12em;

	.About-body {
		padding: 2em 7.5em;
		background-color: $beige;
		width: 82.56em;
		min-height: 100%;
		border-radius: 0.28em;
		
		.About-text {
			font-size: 1em;
			span {
				font-size: 1em;
	
				#om-materialet {
					font-family: Antropos;
					text-align: left;
					font-size: 2.78em;
					color: $green;
					font-weight: bold;
				}
				p {
					font-size: 1.44em;
					text-align: left;
				}
				a {
					color: black;
					text-decoration: underline;
					&:visited {color: black;}
				}
			}
		}
	
		.About-logo {
			position: absolute;
			width: 9em;
			height: 9em;
			bottom: 5em;
			right: 15em;
			background-image: url('../../assets/images/logos/logo-blivnaturligvisround.svg');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
			cursor: pointer;
			&:hover {@include scale(1.05);}
		}
	
		.About-nordea {
			position: absolute;
			z-index: 2;
			bottom: 5em;
			left: 15em;
			width: 12.10em;
			height: 5.5em;
			background-image: url('../../assets/images/logos/logo-nordeafonden-dark.png');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
			cursor: pointer;
			&:hover {@include scale(1.05);}
		}
	}
}