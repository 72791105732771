/* Colors */
$beige: #FFF5E2;
$orange: #EB8142;
$orange-light: #FFC744;
$green: #3F6B49;
$dark-green: #416B4A;
$green-light: #6D9E36;
$lemon: #CCCE66;
$yellow: #FFD25B;
$grey-light: #F0F0F0;
$beige2: #F5E5C7;

/* Images */


/* Icons */

/* scollbar */
$track: #CABDA6;
$thumbHover: #34543B;
