/* COOKIES  */
@-webkit-keyframes fadeInCookie {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fadeInCookie {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}


/* BADGES */
@-webkit-keyframes fade {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fade {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}

@-webkit-keyframes bounce {
	0% {@include scale(0.9);}
	10% {@include scale(0.5);}
	25% {@include scale(1);}
	45% {@include scale(0.9);}
	60% {@include scale(1.05);}
	90% {@include scale(1);}
	100% {@include scale(1);}
}
@keyframes bounce {
	0% {@include scale(0.9);}
	10% {@include scale(0.5);}
	25% {@include scale(1);}
	45% {@include scale(0.9);}
	60% {@include scale(1.05);}
	90% {@include scale(1);}
	100% {@include scale(1);}
}


/* MATERIALS ICON */

@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  80% {
    -webkit-transform: translateX(6px);
            transform: translateX(6px);
  }
  90% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
}
@keyframes shake {
	0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  80% {
    -webkit-transform: translateX(6px);
            transform: translateX(6px);
  }
  90% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
}



