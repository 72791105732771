@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.ChapterConfigure {
	position: relative;
	background-image: url('../../assets/images/background-forest.jpg');
	background-size: cover;
	background-position: bottom center;
	background-repeat: no-repeat;
	height: 100%;
	overflow: hidden;
	user-select: none;

	.ChapterConfigure-header {
		color: white;
		padding-left: 11.9em;
		padding-top: 2.45em;
		.ChapterConfigure-menu {
			@include flex('flex-start', 'flex-start');
			.ChapterConfigure-menuTitle {
				position: relative;
				width: 8em;
				margin-top: 1.3em;
				span {
					font-size: 2.778em;
					font-family: Antropos;
				}
			}
			.ChapterConfigure-menuItems {
				position: relative;
				width: 28.13em;
				height: 5em;
				margin-left: 4.944em;
				@include flex('flex-start', 'flex-start');
				.ChapterConfigure-menuItem {
					cursor: pointer;
					width: 4.46556em;
					height: 5em;
					margin-left: 1.67em;
					background-position: top center;
					background-repeat: no-repeat;
					background-size: contain;
					&:hover {@include scale(1.01);}
					&--1 {
						background-image: url('../../assets/images/chapter-nav-1.svg');
						&.ChapterConfigure-menuItem--selected {background-image: url('../../assets/images/chapter-nav-1-selected.svg');}
					}
					&--2 {
						background-image: url('../../assets/images/chapter-nav-2.svg');
						&.ChapterConfigure-menuItem--selected {background-image: url('../../assets/images/chapter-nav-2-selected.svg');}
					}
					&--3 {
						background-image: url('../../assets/images/chapter-nav-3.svg');
						&.ChapterConfigure-menuItem--selected {background-image: url('../../assets/images/chapter-nav-3-selected.svg');}
					}
					&--4 {
						background-image: url('../../assets/images/chapter-nav-4.svg');
						&.ChapterConfigure-menuItem--selected {background-image: url('../../assets/images/chapter-nav-4-selected.svg');}
					}
					&--5 {
						background-image: url('../../assets/images/chapter-nav-5.svg');
						&.ChapterConfigure-menuItem--selected {background-image: url('../../assets/images/chapter-nav-5-selected.svg');}
					}
				}
			}
		}
		.ChapterConfigure-materials {
			font-size: 1em;
			position: absolute;
			top: 1.5em;
			right: 7.33em;
			.ChapterConfigure-materialsIcon {
				width: 12.667em;
				height: 6.382778em;
				background-image: url('../../assets/images/icon-pdf-white.svg');
				background-repeat: no-repeat;
				background-size: contain;
				background-position: left;
				cursor: pointer;
				&:hover {@include scale(1.05);}
				&.animate {
					-webkit-animation: shake 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
					animation: shake 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
				}
			}
		}
		.ChapterConfigure-infoIcon {
			position: absolute;
			top: 3.35em;
			right: 3.377777777777778em;
			width: 2.5em;
			height: 2.7em;
			background-image: url('../../assets/images/icon-info.svg');
			background-repeat: no-repeat;
			background-size: 3.09em auto;
			background-position: center center;
			&:hover {@include scale(1.01);}
			cursor: pointer;
		}
	}

	.ChapterConfigure-body {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: calc(100% - 10.723em);
		text-align: center;

		.ChapterConfigure-part {
			position: relative;
			display: inline-block;
			vertical-align: top;
			height: 34em;
			width: 26.112em;		
			&::-webkit-scrollbar{display: none;}
			&:nth-child(2) {
				margin-left: 2.0556em;
				margin-right: 2.0556em;
			}
			.ChapterConfigure-partHeader {
				width: 100%;
				height: 3.55em;
				background-color: $green-light;
				@include flex('center', 'center');
				span {
					font-family: Antropos;
					color: white;
					text-transform: capitalize;
					font-size: 2.5em;
					line-height: 1.85;
					font-weight: bold;
				}
				margin-left: 0.11em;
				border-radius: 0.277em 0.277em 0 0;
			}
			.ChapterConfigure-partBodyWrapper {
				padding-top: 0.46em;
				padding-right: 0.6em;
				background-color: $beige2;
				margin-left: 0.11em;
				width: 100%;
				border-radius: 0 0 0.277em 0.277em;
				.ChapterConfigure-partBody {
					overflow: auto;
					height: 33.5em;
					width: 100%;
					padding-top: 0.66em;
					overflow-x: hidden;
					
					&::-webkit-scrollbar {
						width: 0.77em;
					}
					
					/* Track */
					&::-webkit-scrollbar-track {
						border-radius: 0.38em;
						background-color: $track;
						margin-top: 0.55em;
						margin-bottom: 0.55em;
						
					}
					 
					/* Handle */
					&::-webkit-scrollbar-thumb {
						background: $dark-green; 
						border-radius: 0.38em;
					}
					
					/* Handle on hover */
					&::-webkit-scrollbar-thumb:hover {
						background: $thumbHover; 
						
					}

					.ChapterConfigure-activity {
						cursor: pointer;
						position: relative;
						width: 22.223em;
						margin-left: 1.39em;
						min-height: 14.33em;
						padding: 0.4445em 0.95556em;
						&:hover {
							background-color: darken($beige, 3%);
							border-color: darken($beige, 3%);
						}
						
						background-color: $beige;
						text-align: left;
					
						border-color: $beige;
						border-width: 0.2223em;
						border-style: solid;
					
						@include box-shadow(0, 0.1875em, 0.1875em, 0, rgba(black, .3)); 
						margin-bottom: 1.59em;
						
						&--selected {
							border-color: $green-light;
							border-width: 0.2223em;
							border-style: solid;
						}
					
						&--story {
							margin-left: 1.95em;
							overflow: hidden;
							border: none;
							@include box-shadow(0, 0.1875em, 0.1875em, 0, rgba(black, .3)); 
							img {
								position: absolute;
								max-width: 22.24em;
								width: auto;
								height: auto;
								max-height: 12.66em;
								pointer-events: none;
								margin-top: -1.5em;
							}
							padding: 0em;
							&:hover {@include scale(1.01);}
						}
						.ChapterConfigure-textWrapper {	
							position: absolute;
							width: 22.23em;
							height: 4.66em;
							background-color: $beige;
							margin-top: 9.4em;
							background-image: url('../../assets/images/icon-book2.svg');
							background-repeat: no-repeat;
							background-position: 18.2em 1.5em;
							background-size: 2.77778em 1.919444445em;
							padding-left: 0.95em;
							@include flex('center', 'flex-start','column');
							.ChapterConfigure-storyChapter {
								span {
									font-size: 0.9em;
									font-weight: 400;
								}
							}
							.ChapterConfigure-storyTitle{
								span {
									text-align: left;
									font-size: 1.44em;
									font-weight: 700;
								}
							}
						}
						.ChapterConfigure-activityChapter {
							span {
								font-size: 0.9em;
								font-weight: 400;
							}
						}
						.ChapterConfigure-activityTitle {
							width: 100%;
							font-weight: bold;
							font-size: 1.4445em;
							@include no-select();
						}
						.ChapterConfigure-activityDescription {
							font-size: 1.33334em;
							overflow: hidden;
							@include no-select();
						}
						.ChapterConfigure-activityCompetenceWrapper {
							@include flex('flex-start', 'flex-end', 'row');
							position: absolute;
							bottom: 0.25em;
							width: 20.16em;
							height: 3.39em;
							.ChapterConfigure-activityCompetenceRow {
								@include flex('flex-start', 'center', 'row');
								flex-wrap: wrap;
								margin-top: 0.27em;
								.ChapterConfigure-activityCompetenceItem {
									&:not(:last-child) {
										span {
											margin-right: 0.38em;
										}
									}
									margin-top: 0.37em;
									margin-bottom: 0.1em;
									span {
										background-color: $green-light;
										padding-left: 0.72em;
										padding-right: 0.77em;
										font-size: 1.16em;
										font-weight: 700;
										color: white;
										border-radius: 0.72em;
									}
								}
							}
						}
						.ChapterConfigure-activityStatus {
							position: absolute;
							top: -0.47778em;
							right: -1.1em;
							width: 4em;
							height: 4em;
							border-radius: 2em;
							border: 0.25em solid #B9C189;
							background-color: white;

							cursor: pointer;
							@include box-shadow(0.2em, 0.1em, 0.5em, 0, rgb(144, 146, 25));
							&--selected {
								background-image: url('../../assets/images/icon-checkmark.svg');
								background-size: auto 60%;
								background-position: center center;
								background-repeat: no-repeat;
								border: 0.25em solid $green;
							}
							&:hover {
								@include box-shadow(0.25em, 0.15em, 0.5em, 0, rgb(144, 146, 25));	
								&:hover {@include scale(1.05);}
							}
						}
						
					}
				}
			} 
		}
	}
}