@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.CGL {
	position: absolute;
	width: 15em;
	height: auto;
	&:hover {@include scale(1.05);}
	cursor: pointer;
	.CGL-logo{
		background-size: auto auto;
	}

	&.about{
		bottom: 6.5em;
		right: calc(50% - 7.5em);
	}
	&.overview{
		bottom: 2em;
		right: 1.5em;
	}
}