@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.LandingPage {
	position: relative;
	height: 100%;
	width: 100%;
	@include flex('center', 'center');	
	@include no-select();
	overflow: hidden;
	background-image: url('../../assets/images/background-autumn.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	.LandingPage-content {
		width: 50em;
		height: 32em;
		background-image: url('../../assets/images/title.svg'), url('../../assets/images/ask-and-liv.png');
		background-position: bottom center, top center;
		background-size: 100% auto, 38.83em auto;
		background-repeat: no-repeat, no-repeat;
		&:hover{
			cursor: pointer;
			@include scale(1.01);
		}
	}

	.LandingPage-naturligvis {
		z-index: 2;
		position: absolute;
		bottom: 2.239em;
		left: 95.022em;
		width: 9.17em;
		height: 9.17em;
		background-image: url('../../assets/images/logos/logo-blivnaturligvisround.svg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% auto;
	}
	.LandingPage-nordea {
		position: absolute;
		z-index: 2;
		bottom: 2.2em;
		left: 6.3em;
		width: 12.10em;
		height: 5.5em;
		background-image: url('../../assets/images/logos/logo-nordeafonden.png');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
	}
}



