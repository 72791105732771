@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';

@import 'animations';

.chapterSix {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}	

.chapterSix-background {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/background.png');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	z-index: 1;
}
// .chapterSix-clouds {
// 	position: absolute;
// 	width: 200%;
// 	height: 100%;
// 	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-7/clouds.png');
// 	background-repeat: repeat-x;
// 	background-position: center top;
// 	background-size: 50% auto;
// 	z-index: 2;

// 	@include panningAnimation(panRightSix, -100vw, 0vw, 100);
// }

.chapterSix-water {
	z-index: 2;
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/water-01.png');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	-webkit-animation: chapterSixWater 5s linear infinite;
	animation: chapterSixWater 5s linear infinite;

}
.chapterSix-fog {
	position: absolute;

	width: 200%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/fog.png');
	background-repeat: repeat-x;
	background-position: center top;
	background-size: 50% auto;
	&--1 {
		@include opacity(0.6);

		z-index: 2;
		@include panningAnimation(panLeft, 0vw, -100vw, 80);
	}
}

.chapterSix-birds {
	position: absolute;
	width: 18.111em;
	height: 14.11em;
	z-index: 2;
	top: 19em;
	left: -2em;

	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/birds-01.png');
	background-repeat: no-repeat;
	background-size: 600%;
	background-position: 0 0;
	transform: scaleX(-0.2) scaleY(0.2);
	@include opacity(0);
	&--animate {
		-webkit-animation: bird 0.5s infinite steps(6), birdflight2 8s ease-in;
		animation: bird 0.5s infinite steps(6), birdflight2 8s ease-in;
	}
}



.chapterSix-foreground {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-6/foreground.png');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	z-index: 3;
}
