@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.CookieConsent {
	position: absolute;
	@include flex('center', 'center');
	width: 100%;
	height: 100%;
	top:0;
	left: 0;

	.CookieConsent-consentWrapper {
		@include flex('', 'center', 'column');
		position: absolute;
		bottom: 0.571em;
		left: 0.571em;
		width: 4.228em;
		height: 4.428em;
		.CookieConsent-consentToggleBtn:hover {
			cursor: pointer;
			@include opacity(1);
		}
		.CookieConsent-consentToggleBtn {
			@include flex('', 'center', '');
			z-index: 50;
			position: relative;
			width: 100%;
			height: 100%;
			background-image: url('../../../assets/images/cookie/cookies.svg');
			background-size: contain;
			background-repeat: no-repeat;
			@include opacity(0.5);
		}
		&.show {
			.CookieConsent-consentToggleBtn {
				@include opacity(1);
			}
		}
		
	}
	
	.CookieConsent-popup {
		z-index: 50;
		display: flex;
		position: absolute;
		width: 100%;
		height: 0;
		background: #F5E5C7;
		bottom:0;
		flex-direction: row;
		transition: height 0.50s ease-out;
		&.show {
			height: 6em;
			transition: height 0.50s ease-out;
			&.big {
				height: 10em;
				.CookieConsent-buttonWrapper {
					margin-top: 2.5em;
				}
			}
		}
		.CookieConsent-textWrapper {
			position: relative;
			margin-top: 0.57em;
			margin-left: 4.29em;
			width: 82.357em;
			
			.CookieConsent-textBody {
				text-align: left;
				margin-left: 2em;
				margin-top: 0.5em;
				span {
					font-size: 1.414em;
					color: #3F6B49;
					font-family: 'Roboto', sans-serif;
					p {
						margin-block-start: 0em;
						margin-block-end: 0em;
					}
				}
				.CookieConsent-readMore {
					position: absolute;
					left: 30.5em;
					top: 1.7em;
					
					div {
						
						text-decoration: underline;
					}
				}
			}
		}

		.CookieConsent-buttonWrapper {
			width: 10em;
			height: 4.84em;
			margin-left: 6em;
			position: relative;
			margin-top: 0.6em;
			@include flex('space-between', 'space-between', 'column');
			.CookieConsent-button:hover {
				cursor: pointer;
			}
			.CookieConsent-button {
				@include flex('center', 'center', '');
				width: 100%;
				height: 2.266em;
				border-radius: 1em;
				.CookieConsent-buttonText {
					color: white;
					padding: 0.25em 0;
					font-size: 1em;
					font-weight: bold;
					text-transform: uppercase;
				}
			}
			.Ok {
				background-color: #6D9E36;
			}
			.Cancel {
				background-color: #c75e1f;
			}
		}
	}
}
