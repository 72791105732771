@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.ActivityDescription {
  position: absolute;
	width: 100%;
	height: 100%; 
	top: 0;
	left: 0;
	z-index: 5;
	// background-color: $beige;
	user-select: none;
}
.ActivityDescription-contentWraper {
	background-color: $beige;
	width: 82.85556em;
	// height: 54.666em;
	height: 46.666em;
	margin-left: 12.055556em;
	margin-top: 2.944445em;
	margin-bottom: 2.33389em;
	border-radius: 0.2777777777777778em;	
}
.ActivityDescription-header {
	width: 100%;
	height: 8em;
}
.ActivityDescription-infoIcon {
	position: absolute;
	top: 3.35em;
	right: 3.377777777777778em;
	width: 2.5em;
	height: 2.7em;
	background-image: url('../../assets/images/icon-info.svg');
	background-repeat: no-repeat;
	background-size: 3.09em auto;
	background-position: center center;
	&:hover {@include scale(1.01);}
	cursor: pointer;
}

.ActivityDescription-title {
	font-size: 1em;
	padding-top: 2.8277778em;
	padding-left: 9.833334em;
	text-align: left;
	span {
		font-size: 2.7778em;
		font-family: Antropos;
		color: $green;
		font-weight: 600;
	}
}

.ActivityDescription-icons {
	position: absolute;
	top: 4.5em;
	right: 10em;
	text-align: right;
}

.ActivityDescription-body {
	position: relative;

	width: 100%;
	height: calc(100% - 10em); // was 14 em before any padding at the bottom?
	overflow: auto;
	padding: 0 10em;
	img {
		width: 100%;
	}
	&::-webkit-scrollbar{display:none;}
}

.ActivityDescription-box {
	position: relative;
	font-size: 1em;
	padding: 1em 1.5em;
	background-color: white;
	text-align: left;
	margin: 0.5em 0 2em 0;
	h3 {
		color: $green;
		font-family: Antropos;
		font-size: 1.3em;
		padding-bottom: 1em;
		margin-top: 0em;
	}
	
}
.ActivityDescription-boxText {
	position: relative;
	font-size: 1.44445em;
	line-height: 1.5;
}
.ActivityDescription-tracks {
	position: absolute;
	font-size: 1.44445em;
	top: 1.2em;
	right: 1em;
	width: 4em;
	height: 4em;
	background-image: url('../../assets/images/background-tracks.svg');
	background-repeat: no-repeat;
	background-size: contain;
	@include opacity(0.5);
}

.ActivityDescription-sectionContainer {
	position: relative;
	padding: 0.5em 0em 1em 1em;
	margin-top: 0em;
	border-bottom: 0.15em solid $dark-green;
	&.talkAbout {
		border-bottom: 0 solid $dark-green;
	}
}

.ActivityDescription-sectionHeader {
	position: relative;
	font-weight: 600;
	z-index: 1;
	color: $green;
	font-family: Antropos;
	font-size: 2.44445em;
	padding: 0.5em 0em;
	text-align: left;
	
}

.ActivityDescription-sectionBody {
	text-align: left;
	font-size: 1.444445em;
	height: auto;
	overflow: hidden;
	display: block;

	> div {
		p {

			margin-top: 0em;
		}
		ul {
			list-style: none;
			padding-left: 1em;
			margin-bottom: 0.5em;
		}
		li {
			font-size: 1em;
			padding-top: 0.5em;
			position: relative;
			margin-left: 0.5em;
			&:before{
				content: '';
				position: absolute;
				background-image: url('../../assets/images/icon-menu-bullet.svg');
				width: 1.2em;
				height: 1.2em;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
				left: -1.4em;
				top: 0.6em;
			}
		}
	}
}

.ActivityDescription-descriptionReasoning {
	text-align: left;
	font-size: 1.44445em;
	padding-top: 1em;
	padding-left: 1em;
	h3 {
		margin: 0em;
		font-size: 1.25em;
		font-weight: 600;
	}
	p {
		margin-top: 0;
	}
	> div {
		ul {
			list-style: none;
			padding-left: 1em;
		}
		li {
			font-size: 1em;
			padding-top: 0.5em;
			position: relative;
			margin-bottom: 0.5em;
			&:before{
				content: '';
				position: absolute;
				left: -1.4em;
				top: 0.6em;
				background-image: url('../../assets/images/icon-menu-bullet.svg');
				width: 1.2em;
				height: 1.2em;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
			}
		}
	}
}