@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.StoryButton {
	position: relative;
	cursor: pointer;
	&:hover {@include scale(1.01);}
	&--activityDescription {
		display: inline-block;
		vertical-align: top;
		margin: 0 0.5em;
		.StoryButton-icon {@include box-shadow(0, 0.1875em, 0.375em, 0, rgba(#223E43, .6));}
	}
}

.StoryButton-icon {
	width: 4.88889em;
	height: 4.88889em;
	border-radius: 9em;
	background-color: white;
	background-image: url('../../../assets/images/icon-book.svg');
	background-repeat: no-repeat;
	background-size: 70% auto;
	background-position: center center;
	border: 0.25em solid $green;
}