@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Resources {
	height: 100%;
	color: black;
	background-image: url('../../assets/images/background-forest.jpg');
	padding: 2em 12em 12em 12em;
	text-align: left;
	overflow-y: scroll;
	@include hide-scrollbar();
	@include no-select();

	.Resources-content {
		padding: 2.75em 5em;
		background-color: $beige;
		min-height: 100%;

		.Resources-header {
			text-align: left;

			.Resources-text {
				h2 {
					font-size: 2.64em;
					font-family: Antropos;
					color: $green;
					margin-top: 0;
				}
				p {
					font-size: 1.44em;
					line-height: 1.5em;
				}
				a {
					color: black;
					text-decoration: underline;
					&:visited {color: black;}
				}
			}
		}

		.Resources-body {
			margin-top: 3em;
			// padding: 0 10em 2em 10em;
			@include flex('center', 'center');
			flex-wrap: wrap;

			.Resources-pdf {
				display: inline-block;
				vertical-align: top;
				margin: 0.5em;
				text-align: center;
				a {
					display: inline-block;
					height: 15.554em;
					img {
						border: 0.1em solid $green;
						width: 15em;
					}
				}
				&.landscape {
					a img {
						width: 22em;
					}
				}

				.Resources-pdfTitle,
				.Resources-pdfSubTitle {
					overflow: hidden;
					white-space: nowrap;
					color: #323232;
					font-weight: bold;
				}
				.Resources-pdfSubTitle {
					font-weight: normal;
					margin-bottom: 1em;
				}
				
			}
		}
	}
	.Resources-infoIcon {
		position: absolute;
		top: 3.35em;
		right: 3.377777777777778em;
		width: 2.5em;
		height: 2.7em;
		background-image: url('../../assets/images/icon-info.svg');
		background-repeat: no-repeat;
		background-size: 3.09em auto;
		background-position: center center;
		&:hover {@include scale(1.01);}
		cursor: pointer;
	}
}










