@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';

@import 'animations';

.chapterEight {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

}	

.chapterEight-background {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-8/background.png');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	z-index: 1;
}

.chapterEight-fogContainer {
	position: absolute;
	width: 200%;
	height: 100%;
	z-index: 2;
	
	@include translateAndRotate(-6em, 0em, 0, 206deg);
	.chapterEight-fog {
		width: 200%;
		height: 100%;
		background-repeat: repeat-x;
		background-position: center top;
		background-size: 50% auto;
		z-index: 2;
		@include animation(panRightOpacity, 15, 0, infinite, normal, linear, none);
		background-image: url('../../../assets/images/backgrounds-story/animation/chapter-8/fog.png');
	}
}
.chapterEight-birds {
	position: absolute;
	width: 18.111em;
	height: 14.11em;
	z-index: 2;
	top: 12em;
	left: 32em;

	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-8/birds-01.png');
	background-repeat: no-repeat;
	background-size: 600%;
	background-position: 0 0;
	@include scale(0.2);
	@include opacity(0);

	&--animate {
		-webkit-animation: bird 0.5s infinite steps(6) , birdflight 5s ease-in;
		animation: bird 0.5s infinite steps(6), birdflight 5s ease-in;
	}
}


.chapterEight-foreground {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-8/foreground.png');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	z-index: 3;
}

.chapterEight-glow {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/backgrounds-story/animation/chapter-8/glow.png');
	background-repeat: no-repeat;
	background-position: 53em bottom;
	background-size: 60em auto;
	z-index: 4;
	-webkit-backdrop-filter: saturate(100%);
	backdrop-filter: saturate(100%);
	&--1 {
		@include glowAnimation(glowEight, 1, 0, random(50)/100, random(50)/100, random(50)/100);
	}
	&--2 {
		@include glowAnimation(glowEight, 1, 0.2, random(50)/100, random(50)/100, random(50)/100);
	}
	&--3 {
		@include glowAnimation(glowEight, 1.5, 0.2, random(50)/100, random(50)/100, random(50)/100);
	}
}


