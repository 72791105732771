@import 'styles/fonts';
@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/animations';
html {
	width: 100vw;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	font-size: calc(18 * ((100vw / 1920)));
	// overflow: hidden;
}

body {
	width: 100%;
	height: 100%;
	min-height: 100%;
	position: relative;
	font-family: "Open Sans", Arial, sans-serif;
	font-size: 1em;
	font-weight: 400;
	margin: 0;
	padding: 0;
	background-color: $green;
	touch-action: manipulation; /* disables double-tap gestur -> no click delay by the browser */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: none;
	overflow: hidden;
}

#root {
	width: 100%;
	height: 100%;
	min-height: 100%;
}

* {box-sizing: border-box;}

button {
	background-image: none;
	-webkit-tap-highlight-color: transparent;
	@include no-select();
	&:focus {outline: 0;}
	&::-moz-focus-inner {border: 0;}
}

ul, ol {
	padding-inline-start: 1.5em;
	margin: 0;
}

input[type='submit'] {
	@include appearance(none);
}