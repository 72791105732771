@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.BadgeButton {
	position: relative;
	cursor: pointer;
	&:hover {@include scale(1.01);}
	&--activityDescription {
		display: inline-block;
		vertical-align: top;
		margin: 0 0.5em;
	}
}

.BadgeButton-icon {
	width: 9em;
	height: 9em;
	border-radius: 9em;
	background-color: transparent;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;

	&--hunt {
		background-image: url('../../../assets/images/badges/hunt-inactive.svg');
		&.BadgeButton-icon--selected {
			background-image: url('../../../assets/images/badges/hunt-active.svg');
		}
	}
	&--fire {
		background-image: url('../../../assets/images/badges/fire-inactive.svg');
		&.BadgeButton-icon--selected {
			background-image: url('../../../assets/images/badges/fire-active.svg');
		}
	}
	&--fishing {
		background-image: url('../../../assets/images/badges/fishing-inactive.svg');
		&.BadgeButton-icon--selected {
			background-image: url('../../../assets/images/badges/fishing-active.svg');
		}
	}
	&--stretcher {
		background-image: url('../../../assets/images/badges/stretcher-inactive.svg');
		&.BadgeButton-icon--selected {
			background-image: url('../../../assets/images/badges/stretcher-active.svg');
		}
	}
	&--music {
		background-image: url('../../../assets/images/badges/music-inactive.svg');
		&.BadgeButton-icon--selected {
			background-image: url('../../../assets/images/badges/music-active.svg');
		}
	}
	
}