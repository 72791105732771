@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Introduction {
	height: 100%;
	color: black;
	background-image: url('../../assets/images/background-forest.jpg');
	padding: 3em 12em;
	text-align: left;
	overflow: auto;
	@include no-select();
	
	&.popup {
		background-image: none;
		padding: 0;
		.Introduction-content {
			// background-color: transparent;
			padding: 2em 4em;
		}
		.Introduction-text { 
			p, li {
				font-size: 1em;
			}
		}
	}

	.Introduction-content {
		padding: 2.75em 10em;
		background-color: $beige;
		height: 45em;
		border-radius: 0.2777777777777778em;
		

		.Introduction-text {
			height: 40em;
			
			p {
				font-size: 1.44em;
				line-height: 1.5em;
				margin-right: 4em;
			}
			h2, h3{
				color: $green;
			}
			h2 {
				font-size: 2.75em;
				font-family: Antropos;
				margin-top: 0;
			}
			h3 {
				font-size: 1.5em; 
				font-weight: 700;
				margin-bottom: 0;
				margin-top: 2em;
			}
			ul {
				list-style: none;
				padding-left: 1em;
				margin-bottom: 0.5em;
				margin-left: 1em;
			}
			li {
				font-size: 1.44em;
				line-height: 1.85em;
				padding-top: 0.5em;
				position: relative;
				&:before{
					content: '';
					position: absolute;
					background-image: url('../../assets/images/icon-menu-bullet.svg');
					width: 1.5em;
					height: 1.5em;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center center;
					left: -1.5em;
					top: 0.6em;
				}
			}
			.introParts {
				display: inline-block;
				width: 7.5em;
				height: 1.5em;
				background-image: url('../../assets/images/icon-chapter-1.svg'), url('../../assets/images/icon-chapter-2.svg'), url('../../assets/images/icon-chapter-3.svg'), url('../../assets/images/icon-chapter-4.svg'), url('../../assets/images/icon-chapter-5.svg');
				background-size: auto 100%, auto 100%, auto 100%, auto 100%, auto 100%;
				background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
				background-position: left 0 bottom, left 1.5em bottom, left 3em bottom, left 4.5em bottom, left 6em bottom;
		
			}
			.introCheck,
			.introMateriallistIcon,
			.introBadgeIcon {
				display: inline-block;
				width: 1em;
				height: 1em;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: contain;
			}
			.introCheck {background-image: url('../../assets/images/icon-checkmark.svg');}
			.introMateriallistIcon {
				background-image: url('../../assets/images/icon-pdf.svg');
			}
			// .introBadgeIcon {
			// 	background-image: url('../../assets/images/badges/cave-active.png');
		
			// }
		}
	}
	.Introduction-infoIcon {
		position: absolute;
		top: 3.35em;
		right: 3.377777777777778em;
		width: 2.5em;
		height: 2.7em;
		background-image: url('../../assets/images/icon-info.svg');
		background-repeat: no-repeat;
		background-size: 3.09em auto;
		background-position: center center;
		&:hover {@include scale(1.01);}
		cursor: pointer;
	}

	&.page {
		.Introduction-content {
			.Introduction-text {
				height: 40em;
				overflow-y: auto;
				margin-right: -8em;
				margin-left: -4em;
				&::-webkit-scrollbar {
					width: 0.77em;
				}
				
				/* Track */
				&::-webkit-scrollbar-track {
					border-radius: 0.38em;
					background-color: $track;
					margin-top: 0.55em;
					margin-bottom: 0.55em;
				}
				
				/* Handle */
				&::-webkit-scrollbar-thumb {
					background: $dark-green; 
					border-radius: 0.38em;
				}
				
				/* Handle on hover */
				&::-webkit-scrollbar-thumb:hover {
					background: $thumbHover; 
					
				}
			}
		}
	}
}
