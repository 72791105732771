@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Badges {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url('../../assets/images/badges/rewards/background.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	background-color: $beige;
	overflow: hidden;
	@include flex('center', 'flex-end');
	&--configure {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;
	}
}
.Badges-hunt,
.Badges-huntGlow,
.Badges-fire,
.Badges-fireGlow,
.Badges-fishing,
.Badges-fishingGlow,
.Badges-stretcher,
.Badges-stretcherGlow,
.Badges-musicGlow,
.Badges-music {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}

.Badges-hunt {background-image: url('../../assets/images/badges/rewards/hunt.png');}
.Badges-huntGlow {
	background-image: url('../../assets/images/badges/rewards/hunt-glow.png');
	animation: rewardGlow 3s ease-in-out 0s forwards;}
.Badges-fishing {background-image: url('../../assets/images/badges/rewards/fishing.png');}
.Badges-fishingGlow {
	background-image: url('../../assets/images/badges/rewards/fishing-glow.png');
	animation: rewardGlow 3s ease-in-out 0s forwards;
}


.Badges-fire {background-image: url('../../assets/images/badges/rewards/fire.png');}
.Badges-fireGlow {
	background-image: url('../../assets/images/badges/rewards/fire-glow.png');
	animation: rewardGlow 3s ease-in-out 0s forwards;
}
.Badges-stretcher {background-image: url('../../assets/images/badges/rewards/stretcher.png');}
.Badges-stretcherGlow {
	background-image: url('../../assets/images/badges/rewards/stretcher-glow.png');
	animation: rewardGlow 3s ease-in-out 0s forwards;
}
.Badges-music {background-image: url('../../assets/images/badges/rewards/music.png');}
.Badges-musicGlow {
	background-image: url('../../assets/images/badges/rewards/music-glow.png');
	animation: rewardGlow 3s ease-in-out 0s forwards;
}


.Badges-foreground {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	background-image: url('../../assets/images/badges/rewards/foreground.png');
}


.Badges-infoIcon {
	position: absolute;
	top: 3.35em;
	right: 3.377777777777778em;
	width: 2.5em;
	height: 2.7em;
	background-image: url('../../assets/images/icon-info.svg');
	background-repeat: no-repeat;
	background-size: 3.09em auto;
	background-position: center center;
	&:hover {@include scale(1.01);}
	cursor: pointer;
}

.Badges-title {
	position: absolute;
	font-weight: 600;
	top: 1.5em;
	left: 3.5em;
	font-size: 3.25em;
	text-align: left;
	font-family: Antropos;
	color: white;
}

.Badges-wrapper {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	text-align: left;
}



@keyframes rewardGlow {
	0% {@include opacity(1);}
	20% {@include opacity(1);}
	35% {@include opacity(0.5);}
	50% {@include opacity(1);}
	65% {@include opacity(0.5);}
	80% {@include opacity(1);}
	100% {@include opacity(0);}
}