@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Badge {
	display: inline-block;
	width: 8.032em;
	height: 7.638em;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 0em 0em;

	@include no-select();
	cursor: pointer;
	&:hover{@include scale(1.01);}
	&--animate {
		animation: fade 2s linear 1 alternate none;
		animation: bounce 1s ease 1 alternate none;	
	}

	&--hunt {
		position: absolute;
		left: 12em;
		top: 30.05555555555556em;
		background-image: url('../../../assets/images/badges/hunt-inactive.svg');
		&.Badge--selected {background-image: url('../../../assets/images/badges/hunt-active.svg');}
	}

	&--fire {
		position: absolute;
		top: 36.72222222222222em;
		left: 29.66666666666667em;
		background-image: url('../../../assets/images/badges/fire-inactive.svg');
		&.Badge--selected {background-image: url('../../../assets/images/badges/fire-active.svg');}
	}
	
	&--fishing {
		position: absolute;
		left: 50em;
		top: 41.66666666666667em;
		background-image: url('../../../assets/images/badges/fishing-inactive.svg');
		&.Badge--selected {background-image: url('../../../assets/images/badges/fishing-active.svg');}
	}


	&--stretcher {
		position: absolute;
		left: 70.33333333333333em;
		top: 38.44444444444444em;
		background-image: url('../../../assets/images/badges/stretcher-inactive.svg');
		&.Badge--selected {background-image: url('../../../assets/images/badges/stretcher-active.svg');}
	}
	&--music {
		position: absolute;
		top: 30.05555555555556em;
		left: 88em;
		background-image: url('../../../assets/images/badges/music-inactive.svg');
		&.Badge--selected {background-image: url('../../../assets/images/badges/music-active.svg');}
	}

}

.Badge.Badge--overview {
	width: calc(8.032em*0.8);
	height: calc(7.638em*0.8);
	&.Badge--selected {@include no-animation();}
	
	&.Badge--hunt,
	&.Badge--fire,
	&.Badge--fishing,
	&.Badge--stretcher,
	&.Badge--music {
		bottom: 0em;
		left: calc(50% - 3.5em);
		top: -0.5em;
	}
}