@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PrintButton {
	width: 9em;
	position: relative;
	cursor: pointer;
	&:hover {@include scale(1.01);}
	&--materialList {
		position: absolute; 
		top: -0.2em;
		right: -2em;	
		.PrintButton-text{
			text-align: left;
			display: none;
		}
	}
	&--activityDescription {
		display: inline-block;
		vertical-align: top;
		margin: 0 0.5em;
		.PrintButton-icon {@include box-shadow(0, 0.1875em, 0.375em, 0, rgba(#223E43, .6));}
		.PrintButton-text {display: none;}
	}
	&--printPopup {
		position: absolute; 
		top: 1.25em;
		left: 1.625em;	
	}
	&--story {
		display: block;
		position: absolute;
		top: 8.9em;
		right: 3em;
		width: 5em;
		z-index: 11;
		.PrintButton-icon {
			width: 3.8889em;
			height: 3.8889em;
			background-size: 61% auto;
			border: 0.2em solid $green;
		}
		.PrintButton-text {display: none;}
		@include opacity(0);
		&:hover {@include scale(1.00);}
		cursor: default;
	}
	&.story--open {
		@include transition(opacity, 0.5, 'ease');
		@include opacity(1);
		cursor: pointer;
	}
}

.PrintButton-icon {
	width: 4.88889em;
	height: 4.88889em;
	border-radius: 9em;
	background-color: white;
	background-image: url('../../../assets/images/icon-print.svg');
	background-repeat: no-repeat;
	background-size: 70% auto;
	background-position: center center;
	border: 0.25em solid $green;
}

.PrintButton-text {
	font-family: Antropos;
	font-size: 1.8em;
	font-weight: bold;
}


