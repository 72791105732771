@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.MaterialList {
	position: absolute;
	height: 100%;
	width: 100%;
	user-select: none;
	padding: 3em 12em;

	background-image: url('../../assets/images/background-forest.jpg');
	background-size: cover;
	background-position: bottom center;
	background-repeat: no-repeat;
	overflow: hidden;
}
.MaterialList-contentWraper {
	background-color: $beige;
	width: 82.55556em;
	height: 44.666em;
	// margin-left: 12.055556em;
	// margin-top: 2.944445em;
	// margin-bottom: 2.33389em;
	border-radius: 0.2777777777777778em;
	padding: 2.75em 10em;
}

.MaterialList-infoIcon {
	position: absolute;
	top: 0;
	right: 0;
	width: 8em;
	height: 8.5em;
	background-image: url('../../assets/images/icon-info.svg');
	background-repeat: no-repeat;
	background-size: 3.09em auto;
	background-position: top 3.37em right 3.37em;
	&:hover {@include scale(1.01);}
}

.MaterialList-header {
	position: relative;
	height: 5em;
	z-index: 2;
}

.MaterialList-title {
	font-family: Antropos;
	text-align: left;
	font-size: 2.77778em;
	color: $green;
	font-weight: bold;
	margin-bottom: 1em;
}

.MaterialList-body {
	position: relative;
	// width: 100%;
	height: calc(100% - 15em);
	// min-height: 100%;
	height: 90%;
	overflow: auto;
	z-index: 0;
	&::-webkit-scrollbar{ display:none; }
}

.MaterialList-chapter {
	position: relative;
	padding: 8em 0 1em 0;
	text-align: left;
	border-bottom: 0.1111em solid $dark-green;
	background-image: url('../../assets/images/icon-chapter-1.svg');
	background-repeat: no-repeat;
	background-size: 5em auto;
	background-position: left 0 top 2em;
	&--1 {background-image: url('../../assets/images/icon-chapter-2.svg');}
	&--2 {background-image: url('../../assets/images/icon-chapter-3.svg');}
	&--3 {background-image: url('../../assets/images/icon-chapter-4.svg');}
	&--4 {background-image: url('../../assets/images/icon-chapter-5.svg');}
}

.MaterialList-text {
	font-weight: bold;
	line-height: 4em;
}

.MaterialList-activityTitle {
	padding-top: 1em;
	font-weight: bold;
	text-align: left;
}

.MaterialList-list {
	text-align: left;
	ul {
		list-style: none;
		padding-left: 1em;
	}
	li {
		position: relative;
		padding-left: 1.5em;
		padding-top: 0.7em;
		&:before{
			content: '';
			position: absolute;
			background-image: url('../../assets/images/icon-checkbox.svg');
			width: 1.2em;
			height: 1.2em;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			left: 0;
			top: 0.8em;
		}	
	}
}